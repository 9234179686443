.map-note{
    z-index: 401;
    position: fixed;
    top: 30rem;
    right: 5rem;
    background-color: white;
    width: 20rem;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 0.3rem 1.4rem rgba(0,0,0,0.7);
    border: 0.1rem solid gray;
    animation: openNote 0.3s ease;
}

.group-note{
    height: 40rem;
    overflow-y: auto;
}

.note-title{
    font-weight: bold;
    font-size: 1.5rem;
    padding: 1rem 5.7rem 0rem 5.7rem;
    background-color: var(--theme-color);
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    color: white;
}

.btn-note{
    z-index: 401;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    background-color: white;
    height: 2.5rem;
    width: 11rem;
    color: var(--text-color);
    cursor: pointer;
}

.btn-note:hover{
    background-color: rgb(247, 247, 247);
}

.item-note{
    display: flex;
    padding: 1rem 2rem 1rem 2rem ;
}

.img{
    width: 5rem;
    height: 2rem;
    padding-right: 1rem;
}

.title{
    font-size: 1.2rem;
    font-weight: 300;
}

.item-1{
    background-color: #51a6b9;
    color: white;
}


@keyframes openNote {
    0% {
        transform: translateY(-10px);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
}

