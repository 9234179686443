.btn-upload{
    z-index: 401;
    position: fixed;
    top: 1rem;
    left: 45rem;
    background-color: white;
    border-radius: 2rem;
    height: 4rem;
    width: 14rem;
    color: var(--text-color);
    cursor: pointer;
}

.form-upload{
   z-index: 404;
   position: fixed;
   top: 20rem;
   left: 70rem;
   background-color: white;
   height: auto;
   width: 35rem;
   border-radius: 0.5rem; 
}

.background-form{
    z-index: 403;
    background-color: black;
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 80%;
    top: 0;
    left: 0;
}

.item-upload{
    display: flex;
    padding: 1rem;
}

.title-upload{
    background-color: var(--theme-color);
    color: white;
    height: 2.5rem;
    font-size: 1.5rem;
    padding: 0.2rem 1rem;
    font-weight: bold;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
}

.label{
    padding-right: 1rem;
    font-size: 1.2rem;
    width: 10rem;
}

.input{
    border: 0.1rem solid gray;
    padding: 0.1rem;
    border-radius: 0.3rem;
}

.dropdown{
    display: flex;
}

.input-dropdown{
    border: 0.1rem solid gray;
    padding: 0.1rem;
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    width: 80%;
}

.icon{
    border: 0.1rem solid gray;
    padding: 0.1rem;
    cursor: pointer;
}

.group-select{
    z-index: 402;
    border: 0.1rem solid gray;
    position: fixed;
    top:25.5rem;
    left: 81rem;
    background-color: white;
    height: 10rem;
    width: 17.4rem;
    overflow-y: scroll;
}

.group-btn{
    display: flex;
    padding: 1rem;
    text-align: center;
}

.btn-submit{
    width: 16rem;
    height: 2.5rem;
    color: #fff;
    margin-right: 2rem;
    background-color: #198754;
}

.btn-cancel{
    width: 16rem;
    height: 2.5rem;
    background-color: #dc3545;
    color: #fff;
}

.btn-submit:hover{
    background-color: #157347;
    border-radius: #146c43;
}

.btn-cancel:hover{
    background-color: #bb2d3b;
    border-radius: #b02a37;
}

.group-select div{
    font-size: 1.2rem;
}