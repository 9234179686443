.toast {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    border-radius: 0.4rem;
    padding: 1.6rem 1.6rem;
    min-width: 40rem;
    max-width: 45rem;
    border-left: 0.8rem solid;
    margin-bottom: 1.6rem;
    box-shadow: 0 0.5rem 0.8rem rgba(13, 2, 2, 0.58);
    animation: slideInLeft ease 0.3s;

    z-index: 99999;

    position: fixed;
    top: 0.5rem;
    right: 0.5rem;
}

@keyframes slideInLeft {
    from {
        opacity: 0;
        transform: translateX(calc(100% + 3.2rem));
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.toast__body {
    flex: 1;
    font-size: 1.4rem;
}

.toast__title {
    color: #333;
    font-weight: 500;
}

.toast__icon {
    font-size: 2.4rem;
    margin-right: 1.6rem;
}

.toast__message {
    color: #888;
    margin-top: 0.5rem;
}

.toast__close {
    font-size: 2rem;
    color: #888;
    cursor: pointer;
}

.toast__close:hover {
    color: #333;
}

.toast--success {
    border-color: rgb(3, 75, 6);
    .toast__icon {
        color: rgb(3, 75, 6);
    }
}

.toast--info {
    border-color: rgb(17, 141, 237);
    .toast__icon {
        color: rgb(17, 141, 237);
    }
}

.toast--error {
    border-color: rgb(188, 5, 5);
    .toast__icon {
        color: rgb(188, 5, 5);
    }
}

.toast--warning {
    border-color: rgb(196, 129, 5);
    .toast__icon {
        color: rgb(196, 129, 5);
    }
}
