.scale {
    z-index: 403;
    position: fixed;
    width: auto;
    height: auto;
    bottom: 1rem;
    left: 50%;
    background-color: var(--theme-color);
    opacity: 8;
}

.background-scale {
    display: flex;
    flex-direction: row;
}

.scale-value {
    border: 0.1rem solid white;
    padding: 1rem;
    color: white;
}

.feet-value {
    border: 0.1rem solid white;
    padding: 1rem;
    color: white;
}

.group-button{
    z-index: 401;
    position: fixed;
    display: flex;
    flex-direction: row;
    left: 45rem;
    top: 1rem;
}

.btn{
    margin-left: 1rem;
    background-color: white;
    border-radius: 2rem;
    height: 4rem;
    width: auto;
    font-size: 1.4rem;
    color: var(--text-color);
    cursor: pointer;
    padding: 0 2rem;
}

.leaflet-popup-content-wrapper{
    height: auto;
    width: auto;
}