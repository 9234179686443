.form-solution {
    z-index: 404;
    background-color: white;
    position: fixed;
    width: 50rem;
    height: auto;
    max-height: 80rem;
    top: 10rem;
    right: 2%;
    border-radius: 1rem;
    color: black;
}

.background-title {
    display: flex;
    flex-direction: row;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    background-color: var(--theme-color);
}

.title{
    padding-top: 1rem;
    padding-left: 1rem;
    font-weight: bold;
    width: 97%;
}

.remove{
    margin-top: 1rem;
    margin-right: 1rem;
    cursor: pointer;
}

.list-solution {
    height: auto;
    max-height: 40rem;
    background-color: white;
    border-radius: 1rem;
    padding-right: 1rem;
}

.item {
    display: flex;
    flex-direction: row;
    margin: 1rem;
}

.value-name {
    font-size: 1.5rem;
    padding-left: 0.5rem;
}

.title-project {
    font-size: 1.5rem;
    font-weight: bold;
}