.btn-upload{
    z-index: 401;
    position: fixed;
    top: 1rem;
    left: 58rem;
    background-color: white;
    border-radius: 2rem;
    height: 4rem;
    width: 16rem;
    color: var(--text-color);
    cursor: pointer;
}

.background-form{
    z-index: 403;
    background-color: black;
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 80%;
    top: 0;
    left: 0;
}

.form-setting{
    z-index: 404;
    background-color: white;
    position: fixed;
    width: 80rem;
    height: auto;
    max-height: 80rem;
    top: 10rem;
    left: 50rem;
    border-radius: 1rem;
    color: black;
}

.background-title{
    display: flex;
    flex-direction: row;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    background-color: var(--theme-color);
}

.remove{
    margin-top: 1rem;
}

.title{
    padding-top: 1rem;
    padding-left: 1rem;
    font-weight: bold;
    width: 95%;
}

.form-note{
    height: auto;
    max-height: 40rem;
    background-color: white;
    border-radius: 1rem;
}

.item-icon{
    width: 4rem;
    height: 4rem;
    border: 0.1rem solid gray;
    padding: 1rem;
    margin: 1rem;
}

.btn-add-note{
    background-color: var(--theme-color);
    color: white;
    padding: 0.4rem;
    border-radius: 0.3rem;
}

.form-add-setting{
    z-index: 404;
    background-color: white;
    position: fixed;
    width: 50rem;
    height: auto;
    max-height: 80rem;
    top: 10rem;
    left: 60rem;
    border-radius: 1rem;
    color: black;
}

.form-add-note{
    height: 16rem;
    background-color: white;
    border-radius: 1rem;
}

.item{
    display: flex;
    flex-direction: row;
    margin: 3rem 1rem;
}

.item-title{
    width: 20%;
    font-size: 1.4rem;
}

.item-input{
    border: 0.1rem solid gray;
    margin-left: 1rem;
    width: 36rem;
    border-radius: 0.3rem;
    padding: 0.1rem 0.3rem;
}

.item-input-file{
    margin-left: 1rem;
    width: 36rem;
    border-radius: 0.3rem;
    padding: 0.1rem 0.3rem;
}

.btn-group{
    text-align: center;
}

.btn-success{
    background-color: var(--theme-success);
    width: 10rem;
    padding: 0.3rem;
    color: white;
    margin: 1rem;
}

.btn-cancel{
    background-color: var(--theme-delete);
    width: 10rem;
    padding: 0.3rem;
    color: white;
    margin: 1rem;
}

.input-edit-note {
    border: 0.1rem solid gray;
    padding: 0.3rem 1rem;
    border-radius: 0.3rem;
}

.icon-search{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    left: 29%;
    top: 23%;
    font-size: 1.2rem;
    color: #6e6e6e;
}

.group-search {
    flex-direction: row;
    display: flex;
    margin: 1rem;
    height: 2.5rem;
    text-align: center;
}

.input-search {
    border: 0.1rem solid gray;
    font-size: 1.2rem;
    padding-left: 0.3rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border-radius: 0.3rem;
    width: 30rem;
    padding-right: 2.5rem;
    background: url('https://cdn-icons-png.flaticon.com/512/149/149852.png');
    background-size: 1.2rem;
    background-position: right 1rem center;
    background-repeat: no-repeat;
    
    
}

.label {
    padding-left: 1rem;
    font-size: 1.3rem;
    text-align: center;
    padding-top: 0.3rem;
    padding-right: 1rem;
    font-weight: 600;
}