.btn-cross {
    z-index: 401;
    position: fixed;
    top: 1rem;
    left: 108rem;
    background-color: white;
    border-radius: 2rem;
    height: 4rem;
    width: 10rem;
    color: var(--text-color);
    cursor: pointer;
}

.background-form{
    z-index: 403;
    background-color: black;
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 80%;
    top: 0;
    left: 0;
}

.form-cross-section {
    z-index: 404;
    background-color: white;
    position: fixed;
    width: 100rem;
    height: auto;
    max-height: 80rem;
    top: 10rem;
    left: 48rem;
    border-radius: 1rem;
    color: black;
}

.background-title {
    display: flex;
    flex-direction: row;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    background-color: var(--theme-color);
}

.title{
    padding-top: 1rem;
    padding-left: 1rem;
    font-weight: bold;
    width: 97%;
}

.remove{
    margin-top: 1rem;
    margin-right: 1rem;
    cursor: pointer;
}

.group-search {
    display: flex;
    flex-direction: row;
    margin: 1rem;
}

.input-search {
    border: 0.1rem solid gray;
    border-radius: 0.3rem;
    height: 2.1rem;
    padding: 1rem;
    margin-right: 1rem;
    padding-right: 2.5rem;
    background: url('https://cdn-icons-png.flaticon.com/512/149/149852.png');
    background-size: 1.2rem;
    background-position: right 1rem center;
    background-repeat: no-repeat;
    width: 28rem;
}

.label {
    padding-left: 1rem;
    font-size: 1.3rem;
    justify-content: center;
    text-align: center;
    padding-top: 0.3rem;
    padding-right: 1rem;
    font-weight: 600;
}

.btn-upload-file {
    padding: 0.5rem;
    background-color: var(--theme-color);
    border-radius: 0.3rem;
    margin-right: 1rem;
}

.input-search-time {
    border: 0.1rem solid gray;
    border-radius: 0.3rem;
    height: 2.1rem;
    padding: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.title-search-time {
    font-size: 1.4rem;
}

.title-search-provice {
    font-size: 1.4rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.input-search-provice {
    border: 0.1rem solid gray;
    border-radius: 0.3rem;
    height: 2.1rem;
    padding: 1rem;
    margin-left: 0.2rem;
    margin-right: 1rem;
}

.form-cross-section-info {
    z-index: 404;
    background-color: white;
    position: fixed;
    width: auto;
    height: auto;
    max-height: 80rem;
    top: 10rem;
    left: 50rem;
    border-radius: 1rem;
    color: black;
}

.list-info-cross-section {
    display: flex;
    flex-direction: row;
    margin: 1rem;

}

.btn-upload {
    background-color: var(--theme-color);
    padding: 0.3rem;
    font-size: 1.2rem;
    border-radius: 0.3rem;
}

.info-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
}

.info-item-title {
    font-weight: bold;
    width: 17rem;
}

.info-item-input {
    padding: 0.3rem;
    border: 0.1rem solid gray;
    border-radius: 0.3rem;
}

.form-upload{
    z-index: 404;
    background-color: white;
    position: fixed;
    width: 50rem;
    height: auto;
    max-height: 80rem;
    top: 10rem;
    left: 37%;
    border-radius: 1rem;
    color: black;
}

.upload-title {
    width: 17rem;
}

.upload-cross-section {
    margin: 2rem;
}

.group-latlng {
    margin-left: 3rem;
}

.btn-success {
    padding: 0.5rem;
    width: 40%;
    margin-right: 0.5rem;
    background-color: var(--theme-success);
}

.btn-cancel {
    padding: 0.5rem;
    width: 40%;
    margin-left: 0.5rem;
    background-color: var(--theme-delete);
}