.content{
    z-index: 403;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.644);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.form{
    z-index: 404;
    position: fixed;
    top: 6rem;
    left: 45rem;
    height: auto;
    width: 110rem;  
    background-color: white;
    border-radius: 2rem;
    padding: 1rem 1rem;
    max-height: 50rem;
}

.btn{
    width: 13rem;
    height: 2rem;
    background-color: var(--theme-color);
    box-shadow: 1rem;
    text-align: center;
    color: white;
    font-size: 1.2rem;
    border-radius: 0.5rem;
    cursor: pointer;
}

.btn-submit{
    width: 10rem;
    height: 2rem;
    background-color: var(--theme-success);
    text-align: center;
    color: white;
    border-radius: 0.5rem;
    cursor: pointer;
    font-size: 1.3rem;
}

.btn-remove{
    color: var(--theme-delete);
    text-align: center;
}

.btn-close{
    margin-left: 98%;
}

.group-title{
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.title{
    font-weight: bold;
    width: 90%;
}

.name-file{
    font-size: 1rem;
    padding-right: 1rem;
}

.table-container{
    overflow-y: auto;
    height: 10rem;
}

table{
    background-color: black;
    width: 98%;
    margin: 1rem;
}

.th-stt{
    width: 10%;
}

.th-ten{
    width: 40%;
}

.th-ha{
    width: 30%;
}

.th-th{
    width: 20%;
}

table>thead{
    background-color: var(--theme-color);
    color: black;
    position: sticky;
    top: 0;
}

table>tbody{
    background-color: white;
    color: black;
}

td{
    text-align: center;
}

tr>td{
    height: 2rem;
}