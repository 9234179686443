.video{
  padding-top: 0.5rem;
  height: 30rem;
  width: 100%;
  pointer-events: none;
}

.video-container {
  position: relative;
  height: 100%;
  width: 100%;
}
  
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0); /* Màu và độ mờ của lớp phủ */
  pointer-events: none; /* Ngăn chặn sự tương tác với lớp phủ */
}

.volume{
  width: 13%;
}

.btn-group{
  display: flex;
  justify-content: space-between;
  // padding-top: 1rem;
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #00000000;
  color: #333;
  border: none;
  cursor: pointer;
}

.prev-button {
  left: 2.5rem;
}
  
.next-button {
  right: 3rem;
}

.prev-button:hover {
  color: var(--theme-hover-color);
}
  
.next-button:hover {
  color: var(--theme-hover-color);
}