.form {
    margin: 1rem;
    text-align: end;
    width: 98%;
}
.btn{
    border: 0.2rem solid gray;
    padding: 0.3rem;
    margin-right: 0.3rem;
}

.active{
    background-color: var(--theme-color);
}