.loading__indicator:before {
    content: '';
    background: #444343cc;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999998;
}

.loading__indicator:after {
    content: '';
    position: fixed;
    width: 100%;
    top: 50%;
    left: 50%;

    width: 4.8rem;
    height: 4.8rem;
    border: 5px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;

    display: inline-block;
    box-sizing: border-box;

    z-index: 999999;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
