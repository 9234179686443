.btn-upload{
    z-index: 401;
    position: fixed;
    top: 1rem;
    left: 90rem;
    background-color: white;
    border-radius: 2rem;
    height: 4rem;
    width: 17rem;
    color: var(--text-color);
    cursor: pointer;
}

.form-solution {
    z-index: 404;
    background-color: white;
    position: fixed;
    width: 80rem;
    height: auto;
    max-height: 80rem;
    top: 40%;
    left: 40%;
    border-radius: 1rem;
    color: black;
}

.background-form{
    z-index: 403;
    background-color: black;
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 80%;
    top: 0;
    left: 0;
}

.background-title {
    display: flex;
    flex-direction: row;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    background-color: var(--theme-color);
}

.title{
    padding-top: 1rem;
    padding-left: 1rem;
    font-weight: bold;
    width: 97%;
}

.list-solution {
    height: auto;
    max-height: 40rem;
    background-color: white;
    border-radius: 1rem;
}

.remove{
    margin-top: 1rem;
    margin-right: 1rem;
    cursor: pointer;
}

.add{
    background-color: var(--theme-color);
    width: 12rem;
    height: 2rem;
    border-radius: 0.5rem;
    color: white;
    margin-left: 84%;
}

.btn-add-note{
    background-color: var(--theme-color);
    color: white;
    padding: 0.4rem;
    border-radius: 0.3rem;
    cursor: pointer;
}

.action {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.icon {
    padding: 0.5rem;
}

.form-add {
    z-index: 404;
    background-color: white;
    position: fixed;
    width: 50rem;
    height: auto;
    max-height: 80rem;
    top: 25%;
    left: 38%;
    border-radius: 1rem;
    color: black;
}

.form-add-solution {
    height: auto;
    max-height: 40rem;
    background-color: white;
    border-radius: 1rem;
}

.item {
    display: flex;
    flex-direction: row;
    height: 2.5rem;
    width: 96%;
    margin: 1rem;
}

.label {
    margin-right: 0.1rem;
    margin-left: 1.2rem;
    font-size: 1.5rem;
}

.input {
    border: 0.1rem solid gray;
    width: 75%;
    padding: 1rem;
}

.btn-group {
    justify-content:center;
    margin-right: 1.2rem;
}

.btn-success {
    background-color: var(--theme-success);
    color: white;
    width: 15rem;
    height: 2rem;
    border-radius: 0.2rem;
    margin-right: 3rem;
    cursor: pointer;
}

.btn-cancel {
    background-color: var(--theme-delete);
    color: white;
    width: 15rem;
    height: 2rem;
    border-radius: 0.2rem;
    margin-left: 3rem;
    cursor: pointer;
}

.input-edit {
    padding: 0.2rem;
    margin: 0.1rem;
    border: 0.1rem solid gray;
}

.group-search {
    flex-direction: row;
    display: flex;
    margin: 1rem;
    height: 2.5rem;
    text-align: center;
}

.input-search {
    border: 0.1rem solid gray;
    font-size: 1.2rem;
    padding-left: 0.3rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border-radius: 0.3rem;
    width: 30rem;
    padding-right: 2.5rem;
    background: url('https://cdn-icons-png.flaticon.com/512/149/149852.png');
    background-size: 1.2rem;
    background-position: right 1rem center;
    background-repeat: no-repeat;
    
    
}

.label {
    padding-left: 1rem;
    font-size: 1.3rem;
    text-align: center;
    padding-top: 0.3rem;
    padding-right: 1rem;
    font-weight: 600;
}