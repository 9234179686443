.background-form{
    z-index: 403;
    background-color: black;
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 80%;
    top: 0;
    left: 0;
}

.form-setting{
    z-index: 404;
    background-color: white;
    position: fixed;
    width: 40rem;
    height: auto;
    max-height: 80rem;
    top: 10rem;
    left: 75rem;
    border-radius: 1rem;
    color: black;
}

.background-title{
    display: flex;
    flex-direction: row;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    background-color: var(--theme-color);
}

.remove{
    margin-top: 1rem;
}

.title{
    padding-top: 1rem;
    padding-left: 1rem;
    font-weight: bold;
    width: 95%;
}

.form-update{
    height: auto;
    background-color: white;
    border-radius: 1rem;
}

.icon{
    height: 4rem;
    width: 4rem;
    border: 0.1rem solid gray;
    padding: 1rem;
}

.item{
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    margin-left: 2rem;
}

.item-title{
    height: 8rem;
    width: 12rem;
    padding-top: 1rem;
}

.btn{
    background-color: var(--theme-color);
    color: white;
    height: 3rem;
    width: 8rem;
    margin-top: 0.5rem;
    margin-left: 2rem;
    border-radius: 0.5rem;
}

.item-color{
    height: 4rem;
    width: 4rem;
}

.input-color{
    width: 0.0001rem;
    height: 0.0001rem;
    background-color: white;
    opacity: 1%;
    margin-left: 2rem;
}

.form-library{
    border: 0.1rem solid gray;
    width: 95%;
    height: 18rem;
    padding: 1rem;
    margin-bottom: 1rem;
    overflow-y: auto;
    overflow-x: hidden; 
}

.item-img{
    height: 3rem;
    width: 3rem;
}

.item-note{
    display: flex;
    flex-direction: row;
}

.item-title-note{
    padding-top: 0.3rem;
    padding-left: 1rem;
}

.item-note:hover{
    background-color: var(--theme-color);
    color: white;
}

.btn-group{
    text-align: center;
    margin: 1rem;
}

.btn-submit{
    background-color: var(--theme-success);
    padding: 0.3rem;
    color: white;
    margin-left: 1rem;
    margin-right: 1rem;
    width: 10rem;
}

.btn-cancel{
    background-color: var(--theme-delete);
    padding: 0.3rem;
    color: white;
    margin-left: 1rem;
    margin-right: 1rem;
    width: 10rem;
}