.toast-container {
    z-index: 99999;
    position: fixed;
    right: 1rem;
    top: 10rem;
    width: 40rem;
    height: auto;
    opacity: 0;
    visibility: hidden;
    transform: translateX(100%);
    transition: opacity 0.3s, transform 0.3s;
    box-shadow: 0 1px 3px rgba(0,0,0,0.4);
}

.icon{
    padding-right: 1rem;
}

.toast-container.show {
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
    background-color: rgb(255, 255, 255);
}

.toast-container.hide {
    opacity: 0;
    visibility: visible;
    transform: translateX(100%);
    background-color: rgb(255, 255, 255);
}

.toast {
    padding: 8px 16px;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.toast-message {
    flex-grow: 1;
}

.progress-bar {
    height: 4px;
    margin-top: 4px;
}

.toast-close {
    background: none;
    border: none;
    cursor: pointer;
}

.information {
    color: rgb(59, 156, 221);
}

.information-background {
    background-color: rgb(59, 156, 221);
}

.warning{
    color: rgb(251, 255, 7);
}

.warning-background {
    background-color: rgb(251, 255, 7);
}

.success {
    color: rgb(6, 145, 59);
}

.success-background {
    background-color: rgb(6, 145, 59);
}

.dangerous {
    color: rgb(252, 3, 3);
}

.dangerous-background {
    background-color: rgb(252, 3, 3);
}