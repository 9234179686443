.background-form {
    z-index: 403;
    background-color: black;
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 80%;
    top: 0;
    left: 0;
}

.form-register {
    z-index: 404;
    background-color: white;
    position: fixed;
    width: 50rem;
    height: auto;
    max-height: 80rem;
    top: 10rem;
    left: 70rem;
    border-radius: 1rem;
    color: black;
}

.background-title {
    display: flex;
    flex-direction: row;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    background-color: var(--theme-color);
}

.title{
    padding-top: 1rem;
    padding-left: 1rem;
    font-weight: bold;
    width: 97%;
}

.remove{
    margin-top: 1rem;
    margin-right: 1rem;
}

.form-item-register{
    height: auto;
    background-color: white;
    border-radius: 1rem;
}

.item-register{
    display: flex;
    flex-direction: row;
    padding: 1rem;
}

.label-register{
    width: 12rem;
    font-size: 1.4rem;
}

.input-register{
    border-radius: 0.1rem;
    border: 0.1rem solid gray;
    margin-left: 1rem;
    padding: 0rem 1rem;
    width: 70%;
}

.group-btn-register{
    display: flex;
    flex-direction: row; 
}

.button{
    width: 50%;
    padding: 0.5rem 1rem;
    margin: 1rem 1rem;
    border-radius: 0.3rem;
}

.submit{
    background-color: var(--theme-success);
}

.cancel{
    background-color: var(--theme-delete);
}