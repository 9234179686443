.image-slider {
  display: inline;
  height: 100%;
  width: 100%;
}
  
.slider{
  width: auto;
  height: 31rem;
  display: flex;
  justify-content: center; /* Căn giữa theo chiều ngang */
}
.slide {
  width: 100%;
  height: 95%;
  padding-left: 0.6rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.dot{
  width: auto;
  display: flex;
  justify-content: center; /* Căn giữa theo chiều ngang */
  align-items: center;
}
  
.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #00000000;
  color: #333;
  border: none;
  cursor: pointer;
}

.icon-dot{
  width: 0.8rem;
  height: 0.8rem;
  padding-left: 0.5rem;
}
  
.prev-button {
  left: 2.5rem;
}
  
.next-button {
  right: 3rem;
}

.prev-button:hover {
  color: var(--theme-hover-color);
}
  
.next-button:hover {
  color: var(--theme-hover-color);
}