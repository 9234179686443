.popup-menu{
    z-index: 402;
    position: fixed;
    width: 20rem;
    height: auto;
    background-color: white;
    padding: 0.2rem;
    border-radius: 0.4rem;
    box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.item p {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 0.8rem;
    text-align: left;
    font-size: 1.2rem;
    cursor: pointer;
}

.item p:hover{
    background-color: var(--theme-color);
    color: white;
} 