.tabs {
  width: 40rem;
  height: 40rem;
}

.tabs-header {
  display: flex;
  width: 40rem;
}

.tab-item {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
}

.tab-item:hover {
  background-color: var(--theme-hover-color);
  color: #fff;
}

.tab-item.active {
  background-color: var(--theme-color);
  color: #fff;
  border-bottom-color: #fff;
}

.tabs-content {
  border: 1px solid #ccc;
  border-top: none;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 0 0 5px 5px;
  height: 85%;
  overflow-y: auto;
}

.tab-pane {
  display: block;
  height: 10rem;
  width: auto;
  font-size: 1.2rem;
  text-align: center;
}

.item-info{
  height: 100%;
  width: 100%;
  padding: 1.4rem 1rem;
  display: flex;
  flex-direction: row;
}

.label{
  font-weight: bold;
  font-size: 1.2rem;
  width: 8rem;
  text-align: end;
}

.txt {
  flex: 2;
  word-wrap: break-word;
  font-size: 1.2rem;
  padding-left: 0.3rem;
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.video {
  height: 100%;
  width: 100%;
}

.message {
  // padding-top: 1rem;
  font-size: 1.2rem;
}

.tab-upload {
  width: 100%;
  height: 100%;
  padding-top: 10%;
  padding-left: 30%;
}

.btn {
  height: 2.5rem;
  width: 11rem;
  display: flex;
  flex-direction: row;
  background-color: var(--theme-color);
  color: white;
  border-radius: 0.3rem;
  align-items: center;
}

.btn-upload{
  padding-left: 1rem;
  padding-top: 0.5rem;
}

.btn-1{
  height: 2.5rem;
  width: 11rem;
  display: flex;
  flex-direction: row;
  background-color: var(--theme-success);
  color: white;
  border-radius: 0.3rem;
  align-items: center;
}

.name-file{
  display: flex;
  flex-direction: row;
}

.form-setting{
  margin-top: 2rem;
}

.btn-setting{
  background-color: var(--theme-color);
  height: 3rem;
  width: 30rem;
  margin: 1rem;
  color: white;
  cursor: pointer;
}