.btn-user {
    z-index: 401;
    position: fixed;
    top: 1rem;
    left: 119rem;
    background-color: white;
    border-radius: 2rem;
    height: 4rem;
    width: 17rem;
    color: var(--text-color);
    cursor: pointer;
}

.background-form {
    z-index: 403;
    background-color: black;
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 80%;
    top: 0;
    left: 0;
}

.form-user {
    z-index: 404;
    background-color: white;
    position: fixed;
    width: 80rem;
    height: auto;
    max-height: 80rem;
    top: 10rem;
    left: 50rem;
    border-radius: 1rem;
    color: black;
}

.background-title {
    display: flex;
    flex-direction: row;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    background-color: var(--theme-color);
}

.title{
    padding-top: 1rem;
    padding-left: 1rem;
    font-weight: bold;
    width: 97%;
}

.remove{
    margin-top: 1rem;
    margin-right: 1rem;
}

.form-item-user{
    height: auto;
    background-color: white;
    border-radius: 1rem;
}

.icon {
    padding: 0.5rem;
}

.input-search {
    border: 0.1rem solid gray;
    height: 2rem;
    width: 25rem;
    padding: 1rem;
    border-radius: 0.3rem;
    padding-right: 2.5rem;
    background: url('https://cdn-icons-png.flaticon.com/512/149/149852.png');
    background-size: 1.2rem;
    background-position: right 1rem center;
    background-repeat: no-repeat;
}

.form-input-search{
    margin: 1rem;
    width: 80%;
}
.group-tools{
    flex-direction: row;
    display: flex;
}

.btn-add{
    margin: 1rem 0;
    padding: 0.4rem;
    background-color: var(--theme-color);
    border-radius: 0.3rem;
}

.input-edit {
    padding: 0.2rem;
    border: 0.1rem solid gray;
    width: 95%;
}