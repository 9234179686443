.modal-open{
    overflow:hidden
}
.modal{
    position:fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index:1050;
    display:none;
    overflow:hidden;
    outline:0
}
.modal-open .modal{
    overflow-x:hidden;
    overflow-y:auto
}
.modal-dialog{
    position:relative;
    width:auto;
    margin:.5rem;
    pointer-events:none
}
.modal.fade .modal-dialog{
    transition:-webkit-transform .3s ease-out;
    transition:transform .3s ease-out;
    transition:transform .3s ease-out,-webkit-transform .3s ease-out;
    -webkit-transform:translate(0,-25%);
    transform:translate(0,-25%)
}
.modal.show .modal-dialog{
    -webkit-transform:translate(0,0);
    transform:translate(0,0)
}
.modal-dialog-centered{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    min-height:calc(100% - (.5rem * 2))
}
.modal-content{
    position:relative;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    width:100%;
    pointer-events:auto;
    background-color:#fff;
    background-clip:padding-box;
    border:1px solid rgba(0,0,0,.2);
    border-radius:.3rem;
    outline:0
}
.modal-backdrop{
    position:fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index:1040;
    background-color:#000
}
.modal-backdrop.fade{
    opacity:0
}
.modal-backdrop.show{
    opacity:.5
}
.modal-header{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:start;
    -ms-flex-align:start;
    align-items:flex-start;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between;
    padding:1rem;
    border-bottom:1px solid #e9ecef;
    border-top-left-radius:.3rem;
    border-top-right-radius:.3rem
}
.modal-header .close{
    padding:1rem;
    margin:-1rem -1rem -1rem auto
}
.modal-title{
    margin-bottom:0;
    line-height:1.5
}
.modal-body{
    position:relative;
    -webkit-box-flex:1;
    -ms-flex:1 1 auto;
    flex:1 1 auto;
    padding:1rem
}
.modal-footer{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:end;
    -ms-flex-pack:end;
    justify-content:flex-end;
    padding:1rem;
    border-top:1px solid #e9ecef
}
.modal-footer>:not(:first-child){
    margin-left:.25rem
}
.modal-footer>:not(:last-child){
    margin-right:.25rem
}
.modal-scrollbar-measure{
    position:absolute;
    top:-9999px;
    width:50px;
    height:50px;
    overflow:scroll
}
@media (min-width:576px){
    .modal-dialog{
        max-width:500px;
        margin:1.75rem auto
    }
    .modal-dialog-centered{
        min-height:calc(100% - (1.75rem * 2))
    }
    .modal-sm{
        max-width:300px
    }
}
@media (min-width:992px){
    .modal-lg{
        max-width:800px
    }
}

.btn {
    display: inline-block;
    padding: 0.625rem 1.25rem; /* Chuyển đổi từ 10px và 20px thành rem */
    font-size: 0.875rem; /* Chuyển đổi từ 14px thành rem */
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 0.25rem; /* Chuyển đổi từ 4px thành rem */
    cursor: pointer;
}

.btn-secondary {
    background-color: #6c757d;
    color: #fff;
}

.btn-secondary:hover {
    background-color: #5a6268;
}

.btn-primary {
    background-color: #007bff;
    color: #fff;
}

.btn-primary:hover {
    background-color: #0056b3;
}

.btn:focus {
    outline: none;
    box-shadow: 0 0 0 0.1875rem rgba(0, 123, 255, 0.5); /* Chuyển đổi từ 3px thành rem */
}

.btn:active {
    background-color: #004080;
}