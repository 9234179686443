.form{
    z-index: 401;
    position: fixed;
    top: 30%;
    left: 45%;
    background-color: white;
    height: 33rem;
    width: 35rem;
    border-radius: 1rem;
}

.title{
    background-color: var(--theme-color);
    color: white;
    font-size: 1.5rem;
    padding: 0.2rem 1rem;
    font-weight: bold;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
}

.item{
    display: flex;
    padding: 0.5rem;
}

.label{
    padding-right: 1rem;
    font-size: 1.4rem;
    width: 10rem;
}

.input > input{
    border: 0.1rem solid gray;
    padding: 0.1rem;
    border-radius: 0.3rem;
    width: 23rem;
}

.input > textarea{
    border: 0.1rem solid gray;
    padding: 0.1rem;
    border-radius: 0.3rem;
    width: 23rem;
}

.group-btn{
    display: flex;
    padding: 1rem;
    text-align: center;
}

.btn-submit{
    width: 16rem;
    height: 2.5rem;
    color: #fff;
    margin-right: 2rem;
    background-color: #198754;
}

.btn-cancel{
    width: 16rem;
    height: 2.5rem;
    background-color: #dc3545;
    color: #fff;
}