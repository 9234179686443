.map-header {
    z-index: 401;
    position: fixed;
    top: 1rem;
    right: 5rem;
}
.authenIcon{
    width: 5rem;
    height: 5rem;
    margin-left: 25rem;
    margin-bottom: 1rem;
    cursor: pointer;
}

.search {
    z-index: 401;
    position: fixed;
    top: 1rem;
    left: 4rem;
}

.input-search {
    background-color: white;
    height: 4rem;
    width: auto;
    // width: 40.5rem;
}

.open-search{
    border-radius: 16px 16px 0 0;
    border-bottom: 1px solid #dadce0;
    box-shadow: 0 0 2px rgb(0 0 0/20%), 0 -1px 0 rgb(0 0 0/2%);
    border: none;
}

.close-search{
    border-radius: 24px;
    border: none;
}

.box-item-search{
    background-color: #fff;
    border-radius: 0 0 16px 16px;
    box-shadow: 0 2px 4px rgba(0,0,0,.2);
    font-size: 1rem;
    overflow: visible;
    width: 40.5rem;
    padding: 0.5rem 0;
}

.form-search {
    width: 30rem;
    height: 4rem;
    line-height: 1.25rem;
    letter-spacing: 0;
    font-weight: 400;
    white-space: normal;
    padding-left: 1rem;
}

.btn-nav{
    width: 1.5rem;
    height: 1.5rem;
    border: none;
    border-radius: 1rem;
    padding: 0.85rem 4rem 0.5rem 3rem;
    cursor: pointer;
}

.btn-search{
    width: 1.5rem;
    height: 1.5rem;
    border: none;
    border-radius: 1rem;
    padding: 0.8rem 1rem 1rem 1rem;
    cursor: pointer;
}

.icon-bars{
    height: 1.5rem;
    width: 1.5rem;
}

.icon-search{
    height: 1.5rem;
    width: 1.5rem;
}

.box-item{
    height: 3rem;
    width: inherit;
}

.icon-flag{
    height: 1.5rem;
    width: 1.5rem;
    padding: 0.8rem 3rem 0.5rem 4rem;
}

.box-item-title{
    width: 26rem;
    font-size: 1.2rem;
    padding: 0.8rem 0rem 0.5rem 0rem;
    font-weight: 400;
}

.box-item-delete{
    padding: 0.8rem 4rem 0.5rem 4rem;
    font-size: 1.2rem;
}

.icon-close{
    z-index: 404;
    margin-left: 34.8rem;
    padding: 2% 0;
    width: 2%;
    position: fixed;
    animation: openSlideBar 0.3s ease-in;
    cursor: pointer;
    background-color: #fff;
    border: 0.01rem solid rgb(172, 171, 171);
}

.btn-logout{
    background-color: white;
    border-radius: 2rem;
    height: 4rem;
    width: auto;
    font-size: 1.4rem;
    color: var(--text-color);
    cursor: pointer;
    padding: 0 2rem;
}

.hidden {
    display: none;
}

@keyframes openSlideBar {
    0% {
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(0);
    }
}