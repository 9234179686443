.map-slidebar{
  display: flex;
}

.slidebar{
  background-color: white;
  height: 100%;
  width: 35rem;
  z-index: 403;
  position: fixed;
  box-shadow: 0 0.3rem 1.4rem rgba(0,0,0,0.7);
  animation: openSlideBar 0.3s ease;
}

.banner{
  width: 30rem;
  padding-top: 0.5rem;
  padding-left: 1rem;
}

.box-banner{
  border-bottom: 0.1rem solid rgb(202, 201, 201);
}

@keyframes openSlideBar {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}
