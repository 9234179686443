@import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;1,300;1,500&display=swap');

:root {
    --primary-color: #fff;
    --text-color: #333;
    --theme-color: #1b7a8f;
    --theme-hover-color: #2ba3bd;
    --color: #fff;
    --theme-delete: #df3535;
    --theme-success:rgb(44, 187, 68);
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    // 1rem = 10px rule for global
    font-size: 62.5%;
}

body {
    // font-family: 'Open Sans', sans-serif;
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    text-rendering: optimizeSpeed;
    color: var(--text-color);
}

button,
input,
[tabindex] {
    outline: none;
    border: none;
}

a[href] {
    color: var(--text-color);
    text-decoration: none;
}

input[type="checkbox"]:checked{
    background-color: var(--theme-color);
    border-color: var(--theme-color);
    color: var(--theme-color);
}

input[type="range"] {
    accent-color: var(--theme-color);
  }

.hidden {
    display: none;
}