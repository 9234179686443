.map{
    z-index: 401;
    position: fixed;
}
.btn-change-map{
    z-index: 401;
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    background-color: white;
    border-radius: 1rem;
    height: 8rem;
    width: 8rem;
    color: var(--text-color);
    cursor: pointer;
    padding: 0.25rem 0.25rem;
}

.black-overlay {
    position: relative;
}

.black-overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 7.5rem;
    height: 7.5rem;
    border-radius: 1rem;
    background-color: rgba(0, 0, 0, 0.7); 
    z-index: 401;
}

.logo{
    height: 7.5rem;
    width: 7.5rem;
    border-radius: 1rem;
}

.title{
    z-index: 402;
    position: absolute;
    top: 25%;
    left: 10%;
    color: white;
}

.text{
    z-index: 402;
    position: absolute;
    font-size: 1.1rem;
    top: 58%;
    color: white;
    left: 10%;
}

.group-change-map{
    background-color: white;
    width: 21rem;
    height: 8rem;
    z-index: 401;
    position: fixed;
    bottom: 1rem;
    left: 10rem;
    border-radius: 1rem;
    display: flex;
    cursor: pointer;
}

.group-item{
    margin: 0.5rem 1rem ;
}
.logo-item{
    height: 5rem;
    width: 5rem;
    border-radius: 1rem;
}

.title-item{
    font-size: 0.8rem;
}