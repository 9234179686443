.btn{
    z-index: 401;
    position: fixed;
    top: 1rem;
    left: 45rem;
    background-color: white;
    border-radius: 2rem;
    height: 4rem;
    width: 12rem;
    font-size: 1.4rem;
    color: var(--text-color);
    cursor: pointer;
}

.background-form{
    z-index: 403;
    background-color: black;
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 80%;
    top: 0;
    left: 0;
}

.form-folder{
    z-index: 404;
    background-color: white;
    position: fixed;
    width: 80rem;
    height: auto;
    max-height: 80rem;
    top: 10rem;
    left: 55rem;
    border-radius: 1rem;
    color: black;
}

.title{
    padding-top: 1rem;
    padding-left: 1rem;
    font-weight: bold;
    width: 97%;
}

.background-title{
    display: flex;
    flex-direction: row;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    background-color: var(--theme-color);
}

.remove{
    margin-top: 1rem;
}

.add{
    background-color: var(--theme-color);
    width: 12rem;
    height: 2rem;
    border-radius: 0.5rem;
    color: white;
}

.form-table{
    overflow-y: auto;
    overflow-x: hidden;
    height: auto;
}

.item{
    display: flex;
    flex-direction: row;
    margin: 1rem;
}

.item-title{
    width: 30%;
    padding-left: 5%;
    padding-right: 4rem;
}

.item-input{
    border: 0.1rem solid gray;
    width: 60%;
    border-radius: 0.3rem;
}

.group-select{
    // z-index: 405;
    border: 0.1rem solid gray;
    // position: fixed;
    // top:11.5rem;
    // left: 56rem;
    background-color: white;
    height: 10rem;
    width: 58%;
    overflow-y: scroll;
    margin-left: 30.5%;
    margin-bottom: 1rem;
}

.icon{
    border: 0.1rem solid gray;
    cursor: pointer;
    width: 2rem;
    height: 2.5rem;
}

.item-input-dropdown{
    border: 0.1rem solid gray;
    width: 57%;
    border-radius: 0.3rem;
}

.group-submit{
    width: 100%;
    text-align: center;
    padding: 1rem;
}

.button{
    width: 10rem;
    padding: 1rem;
    margin: 1rem 8rem;
    border-radius: 0.3rem;
}

.submit{
    background-color: var(--theme-success);
}

.cancel{
    background-color: var(--theme-delete);
}

.input-edit {
    border: 0.1rem solid gray;
    padding: 0.1rem 0.5rem;
    margin: 0.2rem 0 ;
    border-radius: 0.3rem;
}

.group-edit-folder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0.2rem 0 ;
}

.btn-down {
    padding: 0.2rem;
    border: 0.1rem solid gray;
}

.popup-list-file {
    background-color: white;
    width: 18rem;
    height: auto;
    max-height: 10rem;
    border-radius: 0.3rem;
    border: 0.1rem solid gray;
    color: black;
    overflow-y: auto;
    text-align: start;
}

.popup-list-file p:hover {
    background-color: var(--theme-color);
    color: white;
}

.group-search {
    flex-direction: row;
    display: flex;
    margin: 1rem;
    height: 2.5rem;
    text-align: center;
}

.input-search {
    border: 0.1rem solid gray;
    font-size: 1.2rem;
    padding-left: 0.3rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border-radius: 0.3rem;
    width: 30rem;
    padding-right: 2.5rem;
    background: url('https://cdn-icons-png.flaticon.com/512/149/149852.png');
    background-size: 1.2rem;
    background-position: right 1rem center;
    background-repeat: no-repeat;
    
    
}

.label {
    padding-left: 1rem;
    font-size: 1.3rem;
    text-align: center;
    padding-top: 0.3rem;
    padding-right: 1rem;
    font-weight: 600;
}